<template>
  <v-container class="ma-0 pa-0">
    <poll-discussion
      :surveyID="surveyID"
      :partyID="partyID"
      :getQuestionFunc="getQuestionFunc"
      :getPollFunc="getPollFunc"
      :getCommentsFunc="getCommentsFunc"
      :createCommentFunc="createCommentFunc"
      :updateCommentFunc="updateCommentFunc"
      :deleteCommentFunc="deleteCommentFunc"
      :voteUpCommentFunc="voteUpCommentFunc"
      :voteDownCommentFunc="voteDownCommentFunc"
      :readCommentFunc="readCommentFunc"
      :createCommentAttachmentFormDataFunc="createCommentAttachmentFormDataFunc"
      :createCommentAttachmentFunc="createCommentAttachmentFunc"
      :updateCommentAttachmentFormDataFunc="updateCommentAttachmentFormDataFunc"
      :updateCommentAttachmentFunc="updateCommentAttachmentFunc"
      :deleteCommentAttachmentFunc="deleteCommentAttachmentFunc"
    ></poll-discussion>
  </v-container>
</template>
<script>
import PollDiscussion from "@/components/poll/discussion/PollDiscussion.vue";
import { APIService } from "@/http/APIService.js";

const apiService = new APIService();

export default {
  components: {
    PollDiscussion
  },

  computed: {
    surveyID() {
      return parseInt(this.$route.params.surveyID);
    },

    partyID() {
      return parseInt(this.$route.params.partyID);
    }
  },

  methods: {
    getQuestionFunc(id) {
      return apiService.getSurveyParty(id);
    },

    getPollFunc(id) {
      return apiService.getSurvey(id);
    },

    getCommentsFunc(sort, search, limit, offset, filter) {
      return apiService.getSurveyPartyComments(sort, search, limit, offset, filter);
    },

    createCommentFunc(item) {
      return apiService.createSurveyPartyComment(item);
    },

    updateCommentFunc(item) {
      return apiService.updateSurveyPartyComment(item);
    },

    deleteCommentFunc(id) {
      return apiService.deleteSurveyPartyComment(id);
    },

    voteUpCommentFunc(id) {
      return apiService.voteUpSurveyPartyComment(id);
    },

    voteDownCommentFunc(id) {
      return apiService.voteDownSurveyPartyComment(id);
    },

    readCommentFunc(id) {
      return apiService.surveyPartyCommentRead(id);
    },

    createCommentAttachmentFormDataFunc(data) {
      return apiService.createSurveyPartyCommentAttachmentFormData(data);
    },

    createCommentAttachmentFunc(data) {
      return apiService.createSurveyPartyCommentAttachment(data);
    },

    updateCommentAttachmentFormDataFunc(data) {
      return apiService.updatSurveyPartyCommentAttachmentFormData(data);
    },

    updateCommentAttachmentFunc(data) {
      return apiService.updateSurveyPartyCommentAttachment(data);
    },

    deleteCommentAttachmentFunc(data) {
      return apiService.deleteSurveyPartyCommentAttachment(data);
    },
  }
}
</script>